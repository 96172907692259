/* Debug Menu Styles - Updated to Match Main App's Aesthetics */
@font-face {
  font-family: 'Roboto-Bold';
  src: url('/assets/fonts/Roboto-Bold.ttf') format('truetype');
}


:root {
  --debug-primary-font: 'Roboto-Bold', "sans-serif";
  --debug-secondary-font: "Arial", "monospace";
  --debug-background-color: rgba(0, 0, 0, 0.8);
  --debug-backdrop-color: rgba(255, 255, 255, 0.403);
  --debug-loading-background-color: #000000;
  --debug-title-color: #ffffff;
  --debug-item-background-color: #00000094; /* Slightly different shade for item backgrounds */
  --debug-text-color: #d7d6d4;
  --debug-fog-color: #668daa;
  --debug-submit-area-color: #000000ab;
  --debug-border-color: #ffffff71; /* set0 --title-color, used for borders to add contrast */
  --debug-trans-text-light: rgba(162, 162, 162, 0.5);
  --debug-trans-text-strong: rgba(162, 162, 162, 0.8);
  --debug-button-color: #4CAF50;;
  --debug-button-hover-color: #3c8c3e;;
  --debug-button-remove-color: #c94940;
  --debug-XRButton-hover-color: #9c2d7e;
}

#debugContent {
  background: var(--debug-item-background-color); /* Using a slightly transparent black for contrast */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
  text-align: center;
  max-height: 80%; /* Limiting the height to ensure it doesn't fill the entire screen */
  overflow-y: auto; /* Enabling vertical scroll */
  width: 60%; /* Adjust the width as needed, narrower content area */
  margin: auto; /* Centering the content area */
  color: var(--debug-text-color); /* Consistent text color for readability */
}

#debugMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--debug-background-color); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: var(--debug-primary-font), sans-serif;
  color: var(--debug-text-color);
}

#debugContent button {
  display: block; /* Make buttons block to fill the container width */
  width: 80%; /* Adjust the width as needed */
  margin: 20px auto; /* Center buttons horizontally and add more space between them */
  padding: 15px 10px; /* Increase padding for a larger click area */
  border: 2px solid var(--debug-border-color); /* Make borders thicker for visibility */
  border-radius: 8px; /* Slightly larger radius for a modern look */
  cursor: pointer;
  background-color: var(--debug-button-color);
  color: var(--debug-text-color);
  font-size: 18px; /* Increase font size for readability */
  text-transform: uppercase; /* Optional: make the text uppercase for emphasis */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover effect */
  text-align: center; /* Ensure text is centered */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for depth */
}

#debugContent button:hover {
  background-color: var(--debug-button-hover-color);
  color: var(--debug-title-color); /* Change text color on hover */
  transform: translateY(-2px); /* Slight raise effect on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3); /* Enhanced shadow effect on hover */
}


#debugContent button:hover {
  background-color: var(--debug-button-hover-color);
  color: var(--debug-title-color); /* Change text color on hover for better visibility */
}

.player-info, .connection-info, .inventory-item {
  border: 1px solid var(--debug-border-color);
  background-color: var(--debug-item-background-color); /* Lighter or darker based on preference */
  color: var(--debug-text-color);
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  text-align: left; /* Align text to the left for readability */
}

.player-info h3, .connection-info h3, .inventory-item h3 {
  color: var(--debug-title-color);
}

.player-info p, .connection-info p, .inventory-item p {
  margin: 5px 0;
}

.inventory-item {
  border: 1px solid var(--debug-border-color);
}

.inventory-item h3 {
  font-size: 18px;
}

.inventory-item p {
  font-size: 14px;
}
