.popup-user-manager {
    position: fixed;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    max-width: 400px; /* Reduced for better focus and readability */
    height: 80vh;
    background-color: var(--background-color);
    border: none;
    z-index: 1000;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Softened shadow for depth */
    border-radius: 8px; /* Softer rounded corners */
    font-family: var(--primary-font);
    color: var(--text-color);
    font-size: 1rem; /* Standard font size for readability */
    overflow-y: auto; /* Vertical scroll if needed */
    animation: fadeIn 0.3s ease-in-out;
}

.popup-user-manager h2 { /* Adjusted to new class name */
    font-size: 1.8em;
    margin: 0 0 20px 0;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--title-color);
}

.popup-user-manager strong {
    font-weight: bold;
}

.popup-user-manager em {
    font-style: italic;
}

.popup-user-manager p, /* Adjusted to new class name */
.popup-user-manager img,
.popup-user-manager video {
    margin: 10px 0;
    max-width: 100%;
}

/* Labels inside the popup window */
.popup-user-manager label {
    display: block;
    margin-bottom: 10px;
    color: var(--title-color); /* Gold color like the popup h2 */
}

/* Input fields inside the popup window */
.popup-user-manager input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--submit-area-color); /* Slightly darker blue */
    color: var(--text-color);
    margin-bottom: 20px;
    box-sizing: border-box; /* Ensure padding doesn't affect total width */
}

.popup-user-manager select {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--button-hover-color);
    color: var(--text-color);
    margin-bottom: 20px;
    box-sizing: border-box;
}

.popup-user-manager select option {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--background-color);
    color: var(--text-color);
    margin-bottom: 20px;
    box-sizing: border-box;
}

.popup-user-manager select option:hover {
    background-color: var(--button-hover-color);
}

/* Styling the scrollbar for showroomPopup */
.popup-user-manager::-webkit-scrollbar {
    width: 8px; /* Set width of the scrollbar */
    height: 8px; /* Set height for horizontal scrollbars if any */
}

.popup-user-manager::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1); /* Muted background for the track */
    border-radius: 5px; /* Slightly rounded corners for the track */
}

.popup-user-manager::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4); /* More prominent color for the draggable handle */
    border-radius: 5px; /* Rounded corners for the handle */
}

.popup-user-manager::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.6); /* Slightly brighter color when hovering over the handle */
}

/* Improved hover effect for list items */
#user-list li:hover {
    background-color: var(--button-hover-color);
    transform: scale(1.02); /* Slight increase in size on hover */
}

.modal {
    display: none;
    position: fixed;
    z-index: 1001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.5);
}

.modal-content {
    background-color: var(--background-color);
    margin: 10% auto; /* Centered with more margin */
    padding: 20px;
    border-radius: 8px; /* Consistent border-radius */
    width: 70%; /* Wider for better use of space */
    max-width: 50%; /* Reduced for better focus and readability */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Consistent shadow */
}

.modal-content h2 { /* Adjusted to new class name */
    font-size: 1.8em;
    margin: 0 0 20px 0;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--title-color);
}

.modal-content strong {
    font-weight: bold;
}

.modal-content em {
    font-style: italic;
}

.modal-content p, /* Adjusted to new class name */
.modal-content img,
.modal-content video {
    margin: 10px 0;
    max-width: 100%;
}

/* Labels inside the popup window */
.modal-content label {
    display: block;
    margin-bottom: 10px;
    color: var(--title-color); /* Gold color like the popup h2 */
}

/* Input fields inside the popup window */
.modal-content input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--submit-area-color); /* Slightly darker blue */
    color: var(--text-color);
    margin-bottom: 20px;
    box-sizing: border-box; /* Ensure padding doesn't affect total width */
}

.close-button {
    color: var(--title-color);
    float: right;
    font-size: 1.5rem; /* Larger for easier interaction */
    font-weight: bold;
}

.close-button:hover,
.close-button:focus {
    color: var(--button-hover-color);
    cursor: pointer;
}

.danger-button {
    background-color: var(--button-remove-color);
    color: white;
    padding: 10px 20px; /* More padding for better interaction */
    border-radius: 6px; /* Consistent border-radius */
    transition: background-color 0.2s; /* Faster transition */
    cursor: pointer;
    font-weight: bold; /* Emphasis for danger button */
}

#user-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

#user-list li {
    cursor: pointer;
    padding: 15px; /* Increased padding for touch friendliness */
    background-color: var(--submit-area-color);
    margin-bottom: 8px; /* Increased margin for visual separation */
    border-radius: 6px;
    transition: background-color 0.2s, transform 0.2s; /* Added transform transition */
}

#create-user-btn {
    padding: 10px 20px; /* More padding for touch friendliness */
    border: none;
    border-radius: 6px;
    margin-bottom: 60px;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s; /* Added transform transition */
    background-color: var(--button-color);
    color: var(--text-color);
    font-weight: bold; /* Bold for better readability */
}

#create-user-btn:hover {
    background-color: var(--button-hover-color);
    transform: translateY(-2px); /* Subtle hover effect */
}

/* Enhancing the fade-in animation */
@keyframes fadeIn {
    from { opacity: 0; transform: translate(-50%, -50%) scale(0.95); }
    to { opacity: 1; transform: translate(-50%, -50%) scale(1); }
}

/* Focus styles for interactive elements */
button:focus, input:focus, select:focus {
    outline: 3px solid var(--button-hover-color); /* Stronger focus indication */
    outline-offset: 2px; /* Offset for visual appeal */
}
