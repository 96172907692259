
#pause-gui {
    position: fixed; /* Changed to fixed positioning */
    top: 50%; /* Position halfway down the viewport */
    left: 50%; /* Position halfway across the viewport */
    transform: translate(-50%, -50%); /* Adjust position to truly center */
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    padding: 35px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-family: 'Roboto-Bold', "sans-serif";
    z-index: 1000;
    max-width: 300px;
    width: auto; /* Adjust width as needed */
}

#pause-gui-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
}

#pause-gui-text p {
    margin: 15px 0;
    font-size: 1em;
    text-align: center;
}

#pause-gui-text h3 {
    font-size: 2em;
    text-align: center;
}

#pause-gui-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

#pause-gui-button:hover {
    background-color: #45a049;
}

