.modalMicrophoneAccess {
    display: flex;
    position: fixed;
    z-index: 20001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.877); /* semi-transparent black background */
}

.modalMicrophoneAccess-content {
    background-color: #fff;
    margin: 10% auto; /* Adjusted margin for better centering */
    padding: 20px;
    border-radius: 5px; /* Softened corners */
    width: 40%; /* Adaptive width for different screen sizes */
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    z-index: 20002;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Subtle shadow for depth */
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    align-self: flex-end;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

button#grant-microphone-access-btn {
    background-color: #4CAF50; /* Green */
    color: white;
    padding: 10px 20px;
    margin: 15px 0;
    border: none;
    cursor: pointer;
    border-radius: 5px; /* Rounded corners for the button */
    font-size: 16px;
}

button#grant-microphone-access-btn:hover {
    background-color: #45a049;
}

.microphone-access-decline {
    color: #888;
    font-size: 12px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .modal-content {
        width: 80%; /* More screen width on smaller devices */
    }
}
