@font-face {
    font-family: 'Roboto-Bold';
    src: url('/assets/fonts/Roboto-Bold.ttf') format('truetype');
}

/*Color sets

set0 (current)
--background-color: #2a3d57;
--loading-background-color: #172230;
--title-color: #ffd700;
--text-color: #ffffff;
--submit-area-color: #1a2d47;
--trans-text-light: rgba(255, 255, 255, 0.5);
--trans-text-strong: rgba(255, 255, 255, 0.8);

set1
--background-color: #3d5a85;
--loading-background-color: #2b4468;
--title-color: #ffaa00;
--text-color: #f5f5f5;
--submit-area-color: #2a4d7e;
--trans-text-light: rgba(245, 245, 245, 0.5);
--trans-text-strong: rgba(245, 245, 245, 0.8);

set2
--background-color: #5a3d85;
--loading-background-color: #442b68;
--title-color: #00ffaa;
--text-color: #e8e8e8;
--submit-area-color: #4d2a7e;
--trans-text-light: rgba(232, 232, 232, 0.5);
--trans-text-strong: rgba(232, 232, 232, 0.8);

set3
--background-color: #3d855a;
--loading-background-color: #2b6844;
--title-color: #ff0000;
--text-color: #dddddd;
--submit-area-color: #2a7e4d;
--trans-text-light: rgba(221, 221, 221, 0.5);
--trans-text-strong: rgba(221, 221, 221, 0.8);

set4
--background-color: #855a3d;
--loading-background-color: #68442b;
--title-color: #0000ff;
--text-color: #d3d3d3;
--submit-area-color: #7e4d2a;
--trans-text-light: rgba(211, 211, 211, 0.5);
--trans-text-strong: rgba(211, 211, 211, 0.8);

set5
--background-color: #3d853f;
--loading-background-color: #2b682d;
--title-color: #ff00ff;
--text-color: #c6c6c6;
--submit-area-color: #2a7e38;
--trans-text-light: rgba(198, 198, 198, 0.5);
--trans-text-strong: rgba(198, 198, 198, 0.8);

set6
--background-color: #3f3d85;
--loading-background-color: #2d2b68;
--title-color: #00ffff;
--text-color: #b8b8b8;
--submit-area-color: #382a7e;
--trans-text-light: rgba(184, 184, 184, 0.5);
--trans-text-strong: rgba(184, 184, 184, 0.8);

set7
--background-color: #853d5a;
--loading-background-color: #682b44;
--title-color: #ffff00;
--text-color: #adadad;
--submit-area-color: #7e2a4d;
--trans-text-light: rgba(173, 173, 173, 0.5);
--trans-text-strong: rgba(173, 173, 173, 0.8);

set8
--background-color: #857f3d;
--loading-background-color: #68602b;
--title-color: #ff8800;
--text-color: #a2a2a2;
--submit-area-color: #7e742a;
--trans-text-light: rgba(162, 162, 162, 0.5);
--trans-text-strong: rgba(162, 162, 162, 0.8);

set9
--background-color: #3d8575;
--loading-background-color: #2b685c;
--title-color: #8800ff;
--text-color: #979797;
--submit-area-color: #2a7e6a;
--trans-text-light: rgba(151, 151, 151, 0.5);
--trans-text

set10
--background-color: #5a853d;
--loading-background-color: #44682b;
--title-color: #0088ff;
--text-color: #8c8c8c;
--submit-area-color: #4d7e2a;
--trans-text-light: rgba(140, 140, 140, 0.5);
--trans-text-strong: rgba(140, 140, 140,
*/


:root {
    --primary-font: 'Roboto-Bold', "sans-serif";
    --secondary-font: "Arial", "monospace";
    --background-color: rgba(0, 0, 0, 0.8);
    --backdrop-color: rgba(255, 255, 255, 0.403);
    --loading-background-color: #000000;
    --title-color: #ffffff;
    --text-color: #d7d6d4;
    --fog-color: #668daa;
    --submit-area-color: #000000ab;
    --trans-text-light: rgba(162, 162, 162, 0.5);
    --trans-text-strong: rgba(162, 162, 162, 0.8);
    --button-color: #4CAF50;;
    --button-hover-color: #3c8c3e;;
    --button-remove-color: #c94940;
    --XRButton-hover-color: #9c2d7e;
}
body, html {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: var(--primary-font);
}

#scene-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#startExperienceButton {
    display: block; /* Initially set to block to make it visible */
    position: fixed; /* Fixed position */
    top: 50%; /* Centered vertically */
    left: 50%; /* Centered horizontally */
    transform: translate(-50%, -50%); /* Centering Adjustment */
    padding: 15px 30px; /* Padding */
    background-color: var(--background-color); 
    color: var(--text-color); /* Text color same as dropdown */
    border: none; /* No border */
    border-radius: 10px; /* Rounded corners, similar to dropdown */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* More prominent shadow */
    cursor: pointer; /* Cursor pointer for interactivity */
    font-family: var(--primary-font);
    font-size: 18px; /* Larger font size */
    text-align: center; /* Center the text */
    text-decoration: none; /* Remove underlines */
    transition: background-color 0.3s, transform 0.3s, opacity 0.3s; /* Smooth transitions */
    z-index: 1002; /* Above other elements, higher than dropdown's z-index */
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.6); /* Enhanced Shadow */
    font-weight: bold; /* Bold Font */
    animation: pulse 2s infinite; /* Animation */
}

#startExperienceButton:hover {
    background-color: var(--title-color); 
    transform: translate(-50%, -50%) scale(1.07); /* Slightly larger on hover */
}

@keyframes pulse {
    0% {
        transform: translate(-50%, -50%) scale(1);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.6);
    }
    50% {
        transform: translate(-50%, -50%) scale(1.05);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.6);
    }
}

/* CSS for Quiz Button */
#quizButton {
    display: block;
    position: fixed;
    top: 60%; /* Adjusted */
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    border: none;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    font-family: var(--primary-font);
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s;
    z-index: 1003; /* Above other elements */
}

#quizButton:hover {
    background-color: var(--title-color);
    transform: translate(-50%, -50%) scale(1.05);
}

#backdrop {
    display: none; /* Initially hidden */
    position: fixed; /* Fixed positioning */
    top: 0; 
    left: 0;
    width: 100vw; /* Cover the entire viewport width */
    height: 100vh; /* Cover the entire viewport height */
    background-color: var(--backdrop-color); /* Semi-transparent black */
    z-index: 10; /* Ensure it's below the popup but above other content */
}

#lock-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--trans-text-strong); /* You can adjust the transparency as needed */
    z-index: 101; /* This ensures it overlays everything else */
    display: none;
}


#dot-cursor {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    background-color: var(--title-color);;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none; /* Ensures the dot doesn't interfere with clicks */
    z-index: 100;
}

#dropdown {
    display: none;
    position: absolute;
    right: 0;
    background-color: var(--background-color);  /* Dark bluish background, same as popup */
    border-radius: 10px;  /* Rounded corners, similar to popup */
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);  /* Shadow similar to popup */
    transition: opacity 0.3s, transform 0.3s;  /* Transition similar to popup fadeIn */
    transform: translateY(-10px);
    opacity: 0;
    font-family: var(--primary-font);
    color: var(--text-color);  /* Text color same as popup */
    width: 200px; /* Assuming a fixed width for the dropdown */
    height: 240; /* Fixed height for the dropdown */
    z-index: 1001;  /* Ensure it's above other elements, higher than popup's z-index */
}

#pdf-overlay {
    position: fixed;
    overflow: hidden; /* Hides any overflowing content */
    top: 5%; /* Give some space from the top */
    left: 5%; /* Give some space from the sides */
    right: 5%; /* Give some space from the sides */
    bottom: 5%; /* Give some space from the bottom */
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space between canvas and buttons */
    align-items: center;
    padding: 20px; /* Padding inside the overlay */
}

.buttons-container {
    position: absolute;
    bottom: 10px; /* Position at the bottom of the overlay */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for centering */
    display: flex;
    justify-content: center;
    width: 100%; /* Take up to 100% of the overlay width */
}

#pdf-canvas {

    position: relative;
    top: 10px;
    margin-bottom: 20px; /* Space between canvas and buttons */
}

#pdf-overlay button {
    padding: 10px 15px; /* Comfortable padding for interaction */
    margin: 0 10px; /* Spacing between buttons */
    font-size: 1em; /* Readable font size */
    border: none;
    background-color: var(--background-color); 
    color: var(--text-color);
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); /* Consistent shadow */
    cursor: pointer;
    font-family: var(--primary-font);
    transition: background-color 0.3s, transform 0.3s; /* Consistent transition */
}

#pdf-overlay button:hover {
    background-color: var(--title-color);
}

/* Adjust the top and right properties as needed for positioning */
#close-pdf-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

#prev-page-btn {
    position: absolute;
    left: 10px;
    bottom: 10px;
}

#next-page-btn {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.zoom-btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 15px;
    margin: 0 10px;
}

#zoom-in-btn {
    bottom: 60px;
}

#zoom-out-btn {
    bottom: 10px;
}

.cog-container {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
    -webkit-tap-highlight-color: transparent;
    outline: none;
}

#cog-icon {
    background: none;
    border: none;
    cursor: pointer;
    animation: float 3s ease-in-out infinite;
}

.esc-container {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    display: flex; /* Use flexbox */
    align-items: center; /* Align items vertically in the center */
}

#esc-icon {
    background: none;
    width: 35px;
    height: 35px;
    border: none;
    display: inline-block;
    vertical-align: middle; /* Align the middle of the element with the baseline of the parent */
}

#esc-text {
    font-family: var(--primary-font);
    font-size: 12px;
    color: var(--title-color);
    margin-left: 8px;
    display: inline-block;
    vertical-align: middle; /* Align the middle of the element with the baseline of the parent */
}



@keyframes float {
    0%, 100% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(5px);
    }
}

.dropdown-item {
    padding: 12px 16px;
    display: block;
    font-size: 1em;
    font-weight: 600; /* Make the text slightly bold for better readability */
    text-transform: uppercase; /* Uppercase letters for a modern look */
    letter-spacing: 1px; /* Some spacing between letters */
    color: var(--text-color); /* White text color */
    background-color: var(--background-color); /* Dark bluish background */
    border: none; /* Remove any borders */
    text-align: center; /* Center align the text */
    height: 50px; /* Fixed height for consistency */
    line-height: 50px; /* Vertically center the text */
    transition: background-color 0.3s; /* Smooth background-color transition */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* A subtle separator */
    height: 60px; /* Fixed height for each button */
    width: 100%; /* Full width */
    box-sizing: border-box; /* Ensure padding doesn't affect total width/height */
}

.dropdown-item:last-child {
    border-bottom: none; /* No border for the last item */
}

.dropdown-item:hover {
    background-color: var(--background-color); /* Slightly darker shade on hover */
    cursor: pointer; /* Change cursor to pointer on hover */
}

/* If the dropdown items are anchor <a> tags, you might need to override their default styles */
.dropdown-item a {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Use the parent's color */
    display: block; /* Make it block-level */
    height: 100%; /* Full height */
    width: 100%; /* Full width */
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.showroomPopup-content-container {
    max-height: calc(75vh - 50px); /* This leaves space for the EDIT button */
    overflow-y: auto; /* Allow scrolling */
    
    /* Flexbox styles for responsive design */
    display: flex;
    flex-direction: column;
    align-items: center; /* This will center the children horizontally */
}

.showroomPopup-content-container::-webkit-scrollbar {
    width: 8px; /* Set width of the scrollbar */
    height: 8px; /* Set height for horizontal scrollbars if any */
}

.showroomPopup-content-container::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1); /* Muted background for the track */
    border-radius: 5px; /* Slightly rounded corners for the track */
}

.showroomPopup-content-container::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4); /* More prominent color for the draggable handle */
    border-radius: 5px; /* Rounded corners for the handle */
}

.showroomPopup-content-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.6); /* Slightly brighter color when hovering over the handle */
}

.showroomPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 80vw; /* Use viewport units for responsiveness */
    max-width: 50%; /* Set a maximum width */
    height: auto; /* Let the content define the height */
    background-color: var(--background-color); /* Dark bluish background */
    border: none;
    z-index: 1000;
    padding: 30px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px; /* Rounded corners */
    font-family: var(--secondary-font);
    color: var(--text-color); /* White text color */
    font-size: 2vh; /* Increase font size */
    overflow: auto; /* Add scroll if content is too much */
    animation: fadeIn 0.3s; /* Fade-in animation */
    opacity: 0; /* Start invisible */
    transform: translate(-50%, -50%) scale(0.1); /* Center and scale down the popup */
    transition: opacity 0.5s ease, transform 0.5s ease; /* Smooth animation for opacity and scale */
}

/* Styling the scrollbar for showroomPopup */
.showroomPopup::-webkit-scrollbar {
    width: 8px; /* Set width of the scrollbar */
    height: 8px; /* Set height for horizontal scrollbars if any */
}

.showroomPopup::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1); /* Muted background for the track */
    border-radius: 5px; /* Slightly rounded corners for the track */
}

.showroomPopup::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4); /* More prominent color for the draggable handle */
    border-radius: 5px; /* Rounded corners for the handle */
}

.showroomPopup::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.6); /* Slightly brighter color when hovering over the handle */
}


.showroomPopup h2 { /* Targeting the headline element */
    /* if the word cant fit inside the 80% max width, then the font size should be adjusted */
    font-size: 2vh; /* Increase font size */
    margin: 0,0 20px 0; /* More margin at the bottom for separation */
    font-weight: bold; /* Make it bold */
    font-family: var(--primary-font);
    max-width: 80%;
    hyphens: auto;
    text-align: center; /* Center align the text */
    text-transform: uppercase; /* Convert the text to uppercase for style */
    letter-spacing: 1px; /* Add some letter spacing */
    color: var(--title-color); /* Make it a gold color for distinction, but you can choose another color */
}

.showroomPopup strong {
    font-weight: bold;
}
.showroomPopup em {
    font-style: italic;
}

.showroomPopup img {
    margin: 10px 0; /* Add some spacing between elements */
    max-width: 100%; /* Ensure media doesn't overflow */
}
.showroomPopup video {
    margin: 10px 0; /* Add some spacing between elements */
    max-width: 100%; /* Ensure media doesn't overflow */
}
.showroomPopup iframe {
    width: 80%; /* Adjust as needed */
    height: 50vh; /* Adjust as needed */
    border: none; /* Optional, for styling */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, -55%) scale(0.1); /* Include the scale transformation */
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1); /* Restore the size */
    }
}

.edit-button {
    position: relative;
    bottom: 0;  /* Position it at the bottom of the popup */
    margin-top: 10px;
    left: 0;   /* Start from the left edge of the popup */
    width: 100%;  /* Full width of the popup */
    height: 40px;  /* Fixed height as you mentioned */
    z-index: 10001;  /* Just below the popup */
    background-color: var(--button-color);  /* Gold color for distinction */
    color: var(--title-color);  /* Dark blue text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
    box-sizing: border-box;  /* Ensure border and padding are included in the width and height */
    text-align: center;  /* Center the text inside the button */
}
.edit-button:hover {
    background-color: var(--button-hover-color);
}


.loadingOverlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    max-width: 500px;
    height: auto;
    border: none;
    z-index: 9999;
    padding: 30px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    font-family: var(--primary-font);
    color: var(--title-color);
    font-size: 1.2em;
    overflow: auto;
    animation: fadeIn 0.3s;
}

.loadingText {
    font-family: var(--primary-font);
    color: var(--title-color);
    font-size: 30px;  
    text-align: center;
    z-index: 9999;  
}

.progressBarContainer {
    width: 30vh;
    height: 2px;
    margin-top: 5vh;
    background-color: var(--trans-text-light);
    border-radius: 2px; /* Added for rounded corners */
}

.progressBar {
    height: 100%;
    width: 0%;
    background-color: var(--title-color);
    border-radius: 2px; /* Added for rounded corners */
}

.percentageText {
    margin-top: 20px; /* Added spacing */
}

@media (min-width: 768px) {
    .loadingOverlay {
        flex-direction: column; /* Adjusted for consistency */
    }
}

.loading-background {
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--loading-background-color);
    z-index: 9990; /* Positioned behind the loadingOverlay */
    transition: opacity 3s ease-out; /* 4 seconds fade out effect */
}

.logo {
    max-width: 60%;
    height: auto; /* This ensures the height adjusts to maintain the aspect ratio */
    margin-top: 3vh;
    margin-bottom: 3vh;
    z-index: 10000;
    cursor: pointer;
    object-fit: contain; /* Use this if .logo is an image */
}


.popup-window {
    position: fixed;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the popup */
    width: 80vw; /* Use viewport units for responsiveness */
    max-width: 500px; /* Set a maximum width */
    height: auto; /* Let the content define the height */
    background-color: var(--background-color); /* Dark bluish background */
    border: none;
    z-index: 1000;
    padding: 30px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px; /* Rounded corners */
    font-family: var(--primary-font);
    color: var(--text-color); /* White text color */
    font-size: 1.2em; /* Increase font size */
    overflow: auto; /* Add scroll if content is too much */
    animation: fadeIn 0.3s; /* Fade-in animation */
}

.popup-window h2 { /* Adjusted to new class name */
    font-size: 1.8em;
    margin: 0 0 20px 0;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--title-color);
}

.popup-window strong {
    font-weight: bold;
}

.popup-window em {
    font-style: italic;
}

.popup-window p, /* Adjusted to new class name */
.popup-window img,
.popup-window video {
    margin: 10px 0;
    max-width: 100%;
}

/* Labels inside the popup window */
.popup-window label {
    display: block;
    margin-bottom: 10px;
    color: var(--title-color); /* Gold color like the popup h2 */
}

/* Input fields inside the popup window */
.popup-window input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--submit-area-color); /* Slightly darker blue */
    color: var(--text-color);
    margin-bottom: 20px;
    box-sizing: border-box; /* Ensure padding doesn't affect total width */
}

/* Buttons inside the popup window */
.popup-window button {
    padding: 10px 20px;
    background-color: var(--button-color); /* Gold color for distinction */
    color: var(--title-color); /* Dark blue text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100%; /* Full width of the popup */
    box-sizing: border-box; /* Ensure padding doesn't affect total width */
}

.popup-window button:hover {
    background-color: var(--button-hover-color)
}

.popup-description {
    font-size: 0.8em; /* Making it smaller than the default font-size */
    color:  var(--trans-text-strong); /* Gold color for distinction */
    margin: 0 0 20px 0; /* Add some margin at the bottom for separation */
    text-align: center; /* Center the text */
}

.popupAvatarSelection {

    /* Make the content scrollable */
    overflow-y: auto; /* Vertical scrolling */
    max-height: 80vh; /* Set a maximum height to trigger scrolling */

    /* Style for images inside popupAvatarSelection */
    img {
        max-width: 50%; /* Reduce the size of images */
        height: auto; /* Maintain the aspect ratio */
        display: block; /* Display images as block elements */
        margin: 0 auto 10px; /* Center images and add margin below */
    }
}

.editPopup {
    position: fixed;
    display: none;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    max-width: 600px;
    background-color: var(--background-color);
    border: none;
    z-index: 1000;
    padding: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    font-family: var(--primary-font);
    color: var(--text-color);
    font-size: 1em;
    animation: fadeIn 0.3s;
    max-height: 90vh;
    overflow-y: auto;
}

.editPopup-content-container {
    display: flex;
    flex-direction: column;
    align-items: stretch; /* change to stretch to make sure all children occupy full width */
    width: 100%;
}

.editPopup label {
    display: block;
    font-size: 0.9em;
    margin: 5px 0; /* Adjusted margin */
    font-weight: bold;
    text-align: left; /* Align to left for consistency */
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--title-color);
}

.editPopup textarea {
    width: 100%;
    height: 100px; /* Increased height for better usability */
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: var(--submit-area-color);
    color: var(--text-color);
    font-family: var(--primary-font);
    font-size: 12px;
    resize: vertical;
    box-sizing: border-box;
    margin-bottom: 20px; /* Consistent margin for all form elements */
}

.editPopup input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--submit-area-color);
    color: var(--text-color);
    margin-bottom: 20px;
    box-sizing: border-box;
}

.editPopup select {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--button-hover-color);
    color: var(--text-color);
    margin-bottom: 20px;
    box-sizing: border-box;
}

.editPopup select option {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--background-color);
    color: var(--text-color);
    margin-bottom: 20px;
    box-sizing: border-box;
}

.editPopup select option:hover {
    background-color: var(--button-hover-color);
}

.editPopup button {
    padding: 10px 10px;
    margin-top: 10px; /* Added margin for spacing */
    background-color: var(--button-hover-color);
    color: var(--title-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100%;
    box-sizing: border-box;
}

.editPopup button-clear {
    padding: 10px 10px;
    display: inline-flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    margin-top: 1px; /* Added margin for spacing */
    background-color: var(--button-remove-color);
    color: var(--title-color);
    border: none;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100%;
    height: 5%;
    box-sizing: border-box;
}


.tooltip-icon {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transform: translateY(-2px);
    font-size: 12px;    
    margin-left: 5px;
}

.tooltip-text {
    visibility: hidden;
    width: 200px; /* Set a default width */
    max-width: 200px; /* Prevent tooltip from becoming too wide */
    background-color: black;
    font-size: 12px;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 100; /* Increase z-index to ensure it's on top */
    white-space: normal; /* Wrap text if it's too long */
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.tooltip-icon:hover .tooltip-text,
.tooltip-icon:focus .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    word-break: break-all;
    cursor: pointer;
    transition: border-color 0.3s;
}

.dropzone.hover {
    border-color: #888;
}

.uploading {
    background-color: #f9f9f9;
}

.status-message {
    margin-top: 20px;
    font-style: italic;
}

.loading-spinner {
    border: 5px solid var(--button-color);
    border-top: 5px solid var(--button-hover-color);
    border-radius: 50%;
    width: 100px; 
    height: 100px; 
    animation: spin 1s linear infinite;

    position: fixed;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
}

