.notification {
    padding: 5px 10px;
    font-family: var(--primary-font);
    border-radius: 4px;
    color: white;
    background-color: #333;
    position: fixed;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.notification-flex-container {
    display: inline-flex;
    align-items: center; /* Aligns items vertically */
    justify-content: space-between; /* Distributes space between items */
    width: 100%; /* Ensure the container fills the notification width */
}

.notification-text {
    margin-right: 10px; /* Space between text and button */
}